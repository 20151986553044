import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { Menu } from "../components/Menu/Menu";
import { Navbar } from "../components/Navbar";

const PrivacyWrapper = styled.div`
  margin: 200px auto 100px;
  width: 1280px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`

const PrivacyPolicyPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { PL_PrivacyPolicy, ENG_PrivacyPolicy },
    },
  } = data || {};

  const texts = {
    PL_PrivacyPolicy,
    ENG_PrivacyPolicy,
  };

  const [language, setLanguage] = useState("pl");

  const translate = (key) => {
    return `${language.toUpperCase()}_${key}`;
  };

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);
  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };

  return (
    <Layout>
      <>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <PrivacyWrapper>{texts[translate("PrivacyPolicy")]}</PrivacyWrapper>
      </>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const privacyPolicyPageQuery = graphql`
  query privacyPolicyPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        PL_PrivacyPolicy
        ENG_PrivacyPolicy
      }
    }
  }
`;
